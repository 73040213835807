@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom.css */

/* Webkit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(
    to bottom,
    #7df0bc,
    #78b1f7
  ); /* Set your desired color here */
  border-radius: 6px;
  min-height: 80px;
}

/* Firefox */
body {
  scrollbar-color: linear-gradient(to right, #47cf92, #3c8be9) #f1f1f1; /* Set your desired color here */
}
